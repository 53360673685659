import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { StyledDropdown, StyledDropdownItem } from './dropdown.styled';

const Dropdown = ({ triggerRef, changeDropdownState, children }) => {
  const dropdownRef = useRef(null);

  const [ isNearRightEdge, setIsNearRightEdge ] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (dropdownRef.current) {
        const { right } = dropdownRef.current.getBoundingClientRect();
        setIsNearRightEdge(right > window.innerWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const clickOutside = (e) => {
      if (
        (triggerRef?.current?.contains(e.target) ||
        document.body.classList.contains("modal-open"))
      ) {
        return;
      }
      changeDropdownState(false);
    };
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [ triggerRef, changeDropdownState ]);

  return (
    <StyledDropdown className="mt-2 p-1" ref={dropdownRef} isNearRightEdge={isNearRightEdge}>
      {React.Children.map(children, (child) => {
        return (
          <StyledDropdownItem type={child?.props?.type}>
            {child}
          </StyledDropdownItem>
        );
      })}
    </StyledDropdown>
  );
};

Dropdown.propTypes = {
  changeDropdownState: PropTypes.func.isRequired,
  triggerRef: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default Dropdown;
