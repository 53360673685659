import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledIframe } from './displayFile.styled';
import { __env } from '../../../../envloader';

const Frame = ({
  view
}) => {
  const { i18n } = useTranslation();
  const location = __env.THEME.assets.frames[view.asset];

  return <StyledIframe
    src={`${new URL(__env.THEME_ASSETS_BASE_URL)}${location}?format=raw&lang=${i18n.language}`}
    title="HTML"
    sandbox="allow-same-origin allow-scripts allow-popups"
    contentsecuritypolicy={`default-src ${__env.THEME_ASSETS_BASE_URL}`}
  />;
};

Frame.propTypes = {
  view: PropTypes.object.isRequired,
};

export default Frame;
