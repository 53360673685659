import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import FileCreator from '../nodeCreator/fileCreator';
import DirectoryCreator from '../nodeCreator/directoryCreator';
import SymlinkCreator from '../nodeCreator/symlinkCreator';
import Download from '../button/download';
import Delete from '../button/delete';
import Refresh from '../button/refresh';
import { removeMeta } from '../../utils/urlHelpers.js';
import AddNode from '../nodes/header/addNode/addNode';
import { useIBIS } from '../../hooks/useIbis';
import Overwrite from '../../components/button/overwrite';
import actions from '../../actions';
import { fileCreatorModes } from '../nodeCreator/fileCreator';
import { directoryCreatorModes } from '../nodeCreator/directoryCreator';
import { FilterButton } from './views.styled.js';
import { parseHints } from '../../utils/hints.js';

const BasicControls = ({ onOpenFilterPanel }) => {
  const loginData = useSelector((state) => state.login.get("loginData"));
  const isLoggedIn = loginData && loginData?.hasOwnProperty?.("size");
  const dispatch = useDispatch();

  const IBIS = useIBIS(false);
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const path = pathname.slice(1);

  const isCurrentPathDirectory =
    path.slice(-1) === "/" || path.slice(-1) === "";
  const isCurrentPathMeta = path !== removeMeta(path);
  const [ directoryModal, setDirectoryModal ] = useState(false);
  const [ symlinkModal, setSymlinkModal ] = useState(false);

  const allowed =
    IBIS.node?.allowed?.map((item) => {
      if (item === "modify" && !isCurrentPathDirectory && IBIS.node) {
        return (
          <Overwrite
            key={item}
            setFileModal={() =>
              dispatch(
                actions.setCreateFileModal({
                  open: true,
                })
              )
            }
          />
        );
      }
      if (item === "delete" && IBIS.node) {
        return <Delete key={item} />;
      }
      return null;
    }) ?? null;

  return (
    <>
      {((isCurrentPathDirectory && !isCurrentPathMeta) || !IBIS.node) && (
        <>
          {isLoggedIn && (!IBIS.isLoading || IBIS.node) && (
            <AddNode
              createNewElFromURL={!IBIS.node}
              openFileModal={() =>
                dispatch(
                  actions.setCreateFileModal({
                    open: true,
                  })
                )
              }
              openDirectoryModal={() => setDirectoryModal(true)}
              openSymlinkModal={() => setSymlinkModal(true)}
            />
          )}
          {!!parseHints(IBIS?.node?.hints?.navbar?.sections)?.length &&
            <FilterButton onClick={onOpenFilterPanel}>
              <span />
              <span />
              <span />
            </FilterButton>
          }
          <Refresh disabled={IBIS.isLoading} />
        </>
      )}
      {allowed}
      {!isCurrentPathDirectory && IBIS.node && (
        <Download path={pathname} size={IBIS.node.size} />
      )}
      {
        <>
          <DirectoryCreator
            modal={directoryModal}
            closeModal={() => setDirectoryModal(false)}
            mode={
              IBIS.node
                ? directoryCreatorModes.add
                : directoryCreatorModes.create
            }
          />
          <SymlinkCreator
            modal={symlinkModal}
            closeModal={() => setSymlinkModal(false)}
          />
          <FileCreator
            mode={
              !isCurrentPathDirectory && IBIS.node
                ? fileCreatorModes.modify
                : IBIS.node
                  ? fileCreatorModes.add
                  : fileCreatorModes.create
            }
          />
        </>
      }
    </>
  );
};

export default BasicControls;

BasicControls.propTypes = {
  loginStore: PropTypes.object,
  onOpenFilterPanel: PropTypes.func
};
