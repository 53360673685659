import i18n from './i18n';

const backendTranslation = (field, ifEmptyValue = '') => {
  const lang = i18n.language && [ 'pl', 'en' ].includes(i18n.language.substring(0,2)) ? i18n.language.substring(0,2) : 'pl';
  
  if (field && typeof field === 'object' && field !== null) {
    return field.hasOwnProperty(lang) ? field[lang] : ifEmptyValue;
  }
  return field || ifEmptyValue;
};

export default backendTranslation;