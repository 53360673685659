import React from 'react';
import { Nav, Collapse } from 'reactstrap';
import { Route, Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

import TopbarButtons from '../../components/hints/topbar';
import Login from '@main/containers/login';
import { icons } from '@assets/svg/icons/index.js';
import { StyledNavbar, StyledLogoWrapper, VerticalLine, ButtonContainer } from './topbar.styled';
import { useIBIS } from '../../hooks/useIbis';
import { theme } from '../../styles/index';
import LanguageSwitcher from '../../../main/components/topbar/LanguageSwitcher';
import useForceUpdateOnLangChange from '../../../main/utils/useForceUpdateOnLangChange';

const TopBar = () => {
  useForceUpdateOnLangChange();
  const queryParams = new URLSearchParams(window.location.search);
  const disableTopbar = queryParams.get("topbar") === "false";


  const url = theme.mainLogo
    ? URL.createObjectURL(new Blob([ theme.mainLogo ], { type: "image/svg+xml" }))
    : null;

  const IBIS = useIBIS(false);
  if (
    disableTopbar ||
    IBIS.node?.hints?.topbar?.visibility === "hidden" ||
    IBIS.node?.hints?.topbar?.visibility === "collapsed"
  )
    return null;

  return (
    <StyledNavbar expand>
      <NavbarBrand
        className="h-100"
        tag={Link}
        to={"/" + window.location.search}
      >
        {url ? (
          <img src={url} alt="logo"></img>
        ) : (
          <StyledLogoWrapper className='h-100'>
            { icons['logo-negative'] }
          </StyledLogoWrapper>
        )}
      </NavbarBrand>
      <TopbarButtons />
      <ButtonContainer>
        <LanguageSwitcher />
        <VerticalLine />
        <Collapse navbar>
          <Nav className="h-100" navbar pills>
            <Route strict path="/" render={(props) => <Login />} />
          </Nav>
        </Collapse>
      </ButtonContainer>
    </StyledNavbar>
  );
};

export default TopBar;
