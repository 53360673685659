import { useEffect, useState } from 'react';
import i18n from './i18n';

const useForceUpdateOnLangChange = () => {
  const [ tick, setTick ] = useState(0);

  useEffect(() => {
    const onLanguageChanged = () => setTick(!tick);
    i18n.on('languageChanged', onLanguageChanged);
    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [ tick ]);
};

export default useForceUpdateOnLangChange;