import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { success } from '../../main/utils/notify';
import Loading from '../../main/components/loading';
import { Button } from '../components/button/button';
import Edit from '../components/button/edit';
import { useIBIS } from '../hooks/useIbis';
import Views, { viewsLabels } from '../components/hints/views';
import Node from '../components/nodes/node';
import { getDefaultView } from './mainDisplay';
import DropdownButton from '../components/dropdown/dropdownButton';
import { StyledDropdownButton } from '../components/dropdown/dropdown.styled';
import { parseHints } from '../utils/hints';
import { isFileEditable } from '../utils/editableFileType';
import useForceUpdateOnLangChange from '../../main/utils/useForceUpdateOnLangChange';

const NodeContainer = ({ views }) => {
  useForceUpdateOnLangChange();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const notFoundMessage = pathname.endsWith("/")
    ? t("ibis-ui:directory_not_found")
    : t("ibis-ui:does_not_exist");

  const [ path, setPath ] = useState(pathname);
  const editorRef = useRef(null);
  const submitRef = useRef(null);

  const IBIS = useIBIS(path !== pathname, path);

  const [ isEditing, setIsEditing ] = useState(false);
  const [ view, setView ] = useState(0);
  const [ mode, setMode ] = useState(0);
  const isDirectory = path.lastIndexOf("/") === path.length - 1;

  useEffect(() => {
    setPath(null);
    setView(0);
  }, [ pathname ]);

  useEffect(() => {
    setView(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ views[0]?.type ]);

  useEffect(() => {
    const newPath =
      views[0]?.type === viewsLabels.DELEGATE
        ? pathname.split("/").slice(0, -1).join("/") +
          "/" +
          (views[0]?.path ?? "")
        : views[0]?.type === viewsLabels.LIST &&
          views[0]?.path &&
          views[0]?.path.slice(-1) !== "/"
          ? pathname + views[0]?.path + "/"
          : pathname + (views[0]?.path ?? "");
    setPath(newPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ views[0]?.path ]);

  const saveFile = (data) => {
    const fileName = path.split("/").pop();
    const currentValue =
      views[view].type === viewsLabels.FORM
        ? JSON.stringify(data.formData)
        : data;
    const file = new File([ currentValue ], fileName, {
      type: IBIS.node.contentType,
    });

    IBIS.patchFile({ file, pathname }).then(() =>
      success(t("ibis-ui:succesful_save"))
    );
    setIsEditing(false);
  };

  if (!IBIS.node && IBIS.isLoading) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (IBIS.error)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>
          {IBIS.error.response?.status === 403
            ? t("ibis-ui:forbidden")
            : IBIS.error.response?.status === 404
              ? notFoundMessage
              : t("ibis-ui:backend_error")}
        </h6>
      </Row>
    );

  if (!IBIS.node)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{notFoundMessage}</h6>
      </Row>
    );

  const canModify =
    !isDirectory &&
    views[view]?.type !== viewsLabels.IMAGE &&
    IBIS.node?.allowed?.includes("modify") &&
    isFileEditable(IBIS.node?.contentType);

  const disableSidebar =
    new URLSearchParams(window.location.search).get("sidebar") === "false";

  const modes = parseHints(views[view].modes);
  return (
    <div className={`h-100 ${disableSidebar ? "px-3" : ""}`}>
      <div className="h-100 d-flex flex-column">
        <div className='d-flex'>
          {modes.length > 1 && (
            <div className="ml-3">
              <DropdownButton>
                {modes.map((mode, index) => (
                  <StyledDropdownButton
                    className="p-1"
                    onClick={() => setMode(index)}
                    key={mode.type}
                  >
                    {mode.label}
                  </StyledDropdownButton>
                ))}
              </DropdownButton>
            </div>
          )}
          <Views
            setView={setView}
            setStartPath={setPath}
            views={views}
            selectedView={view}
          />
          {canModify && (
            <Col className="m-3">
              <Row className="justify-content-end">
                <Col>
                  <div className="d-flex justify-content-end">
                    <Edit isEditing={isEditing} setIsEditing={setIsEditing}/>
                    {isEditing &&
                      (views[view].type === viewsLabels.FORM ? (
                        <Button
                          className="ml-2 mr-3"
                          onClick={() => {
                            submitRef.current.click();
                          }}
                        >
                          {t("ibis-ui:save")}
                        </Button>
                      ) : (
                        <Button
                          className="ml-2 mr-3"
                          onClick={() => saveFile(editorRef.current.getValue())}
                        >
                          {t("ibis-ui:save")}
                        </Button>
                      ))}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </div>
        <Node
          node={IBIS.node}
          fetchMore={IBIS.fetchMore}
          view={
            views[view]?.type === viewsLabels.DELEGATE
              ? { type: getDefaultView(IBIS.node, path) }
              : views[view]
          }
          mode={modes[mode]}
          isEditing={isEditing}
          startPath={path}
          saveFile={saveFile}
          getEmbedContent={IBIS.getEmbedContent}
          isLoading={IBIS.isLoading}
          editorRef={editorRef}
          submitRef={submitRef}
        />
        {IBIS.isLoading && (
          <Row className="m-0 mt-4 justify-content-center align-items-center">
            <div>
              <Row className="justify-content-center">
                <Loading />
              </Row>
              <h6>{t("ibis-ui:loading")}</h6>
            </div>
          </Row>
        )}
      </div>
    </div>
  );
};

NodeContainer.propTypes = {
  views: PropTypes.array,
};

export default NodeContainer;
