import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import { __env } from '../../../../envloader';
import { isInFileProviderMode } from '../../../utils/urlHelpers';

export const NodeContainer = ({
  startPath,
  setStartPath,
  name,
  type,
  children,
  target,
  fileProvider,
}) => {
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const history = useHistory();

  const selectFile = fileProvider ?? isInFileProviderMode();

  const notifyHost = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.top.postMessage(
      __env.IBIS_TENANT_URL.slice(0, -1) +
        (startPath ?? pathname) +
        name +
        (type === "directory" ? "/" : ""),
      "*"
    );
  };

  const newPath =
    (type === "directory"
      ? `${(startPath ?? "") + name}/`
      : type === "symlink"
        ? target
        : `${(startPath ?? "") + name}`) + window.location.search;

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onMouseDown: (e) => {
        if (e.button === 1 || e.ctrlKey || e.metaKey) {
          window.open(`${__env.IBIS_TENANT_URL}${newPath.slice(1)}`, '_blank');
        }
      },
      onClick: () =>
        type === "file" && selectFile
          ? notifyHost
          : setStartPath
            ? type === "directory"
              ? setStartPath(newPath)
              : null
            : history.push(newPath),
      className: "m-5",
      notifyHost: selectFile ? notifyHost : null,
    });
  });
};

NodeContainer.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.element,
  target: PropTypes.string,
  startPath: PropTypes.string,
  fileProvider: PropTypes.bool,
};

export default NodeContainer;
