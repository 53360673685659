import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../ibis-ui/components/dropdown/dropdown';
import { LanguageButton, LanguageContainer } from './LanguageSwitcher.styled';
import { icons } from '../../../assets/svg/icons';
import { LANGUAGES } from '../../utils/i18n';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const dropdownRef = useRef();

  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const [ currentLang, setCurrentLang ] = useState(i18n.language?.toUpperCase() || 'EN');

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLang(lng.toUpperCase());
    setIsDropdownOpen(false);
  };

  return (
    <LanguageContainer ref={dropdownRef}>
      <LanguageButton onClick={toggleDropdown}>{currentLang} {icons.down}</LanguageButton>
      {isDropdownOpen && (
        <Dropdown triggerRef={dropdownRef} changeDropdownState={setIsDropdownOpen}>
          {LANGUAGES.map(({ code, label }) => (
            <button
              key={code}
              onClick={() => handleChangeLang(code)}
              className={`dropdown-item ${currentLang.toLowerCase() === code ? 'active' : ''}`}
            >
              {label}
            </button>
          ))}
        </Dropdown>
      )}
    </LanguageContainer>
  );
}