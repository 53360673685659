import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import { StyledDropdownIcon } from '../dropdown/dropdown.styled';
import { StyledTab, StyledTabContainer } from './views.styled';
import { ReactComponent as Tile } from '../../../assets/svg/icons/tile.svg';
import { ReactComponent as List } from '../../../assets/svg/icons/list.svg';
import backendTranslation from '../../../main/utils/backendTranslation';
import useForceUpdateOnLangChange from '../../../main/utils/useForceUpdateOnLangChange';

export const viewsLabels = {
  LISTING: "listing",
  SELECTOR: "selector",
  JSON: "json",
  FORM: "form",
  TEXT: "text",
  IMAGE: "image",
  MARKDOWN: "markdown",
  DOWNLOAD: "download",
  EMBED: "embed",
  FRAME: "frame",
  DELEGATE: "delegate",
  PDF: "pdf"
};

const Views = ({ setView, selectedView, views, setStartPath }) => {
  useForceUpdateOnLangChange();
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const options = views.map((view, index) => {
    return (
      <StyledTab
        key={backendTranslation(view.label) + index}
        active={selectedView === index}
        onClick={() => {
          const newPath =
            view.type === viewsLabels.DELEGATE
              ? pathname.split("/").slice(0, -1).join("/") +
                "/" +
                (view.path ?? "")
              : pathname + (view.path ?? "");
          setStartPath(newPath);
          setView(index);
        }}
      >
        <StyledDropdownIcon className="pr-2">
          {view.type === viewsLabels.GRID ? (
            <Tile className="align-self-center" />
          ) : view.type === viewsLabels.LIST ? (
            <List className="align-self-center" />
          ) : (
            <List className="align-self-center" />
          )}
        </StyledDropdownIcon>
        {backendTranslation(view.label)}
      </StyledTab>
    );
  });

  if (options.length <= 1) return <div></div>;

  return <StyledTabContainer>{options}</StyledTabContainer>;
};

Views.propTypes = {
  setView: PropTypes.func.isRequired,
  selectedView: PropTypes.number.isRequired,
  views: PropTypes.array.isRequired,
  setStartPath: PropTypes.func.isRequired,
};

export default Views;
