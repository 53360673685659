import qs from 'qs';
import axios from 'axios';

import { __env } from '../../../envloader';
import { getGV, setGV } from '../globalVariablesProvider';
import { checkIfLoginIsPossible, getAuthConfig } from '../authUtils';
import { logInRedirect, verifyAccessToken } from '../../../oidc-middleware/utils/loginUtils';

export default class AccessTokenProvider {

  _gettingTokenInProgress = null;

  _getAccessToken = () => {
    const that = this;
    const gettingPromise = new Promise((resolve, reject) => {

      const data = {
        grant_type: 'refresh_token',
        client_id: __env.AUTH_CLIENT_ID,
        refresh_token: getGV('refreshToken'),
      };
      //if AUTH_CLIENT_SECRET is empty, null or undefined it wont be sent
      if (__env.AUTH_CLIENT_SECRET && 0 !== __env.AUTH_CLIENT_SECRET.length) {
        data.client_secret = __env.AUTH_CLIENT_SECRET;
      }
      getAuthConfig().then((config) => {
        axios.post(config.AUTH_TOKEN_ENDPOINT, qs.stringify(data),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(function (response) {
            verifyAccessToken(response.data.access_token);
            // accessToken refreshed successfully - saving in global variable
            setGV('accessToken', response.data.access_token);
            setGV('refreshToken', response.data.refresh_token);
            that._gettingTokenInProgress = null;
            resolve(response.data.access_token);
          })
          .catch((error) => {
            // Refresh token fail - try silent login
            logInRedirect('none', 'after-token-refresh-error');
          });
      });
    });
    this._gettingTokenInProgress = gettingPromise;
    return gettingPromise;
  };

  obtainToken(force = false) {
    if (__env.IBIS_AUTH_SECRET) {
      return __env.IBIS_AUTH_SECRET;
    }

    if (!checkIfLoginIsPossible()) {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }

    const accessToken = getGV('accessToken');
    if (!force && accessToken) {
      return new Promise((resolve, reject) => {
        // not forcing and accessToken exists in global variable
        //TODO: Check if accessToken hasn't expired
        resolve(accessToken);
      });
    }
    else {
      // force mode or accessToken does not exist in global variable - getting accessToken

      if (!this._gettingTokenInProgress) {
        //There is no getting access token in progress
        return this._getAccessToken();
      }
      else {
        //There is some getting access token in progress
        return this._gettingTokenInProgress;
      }
    }
  }
}
