import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useIBIS } from '../../hooks/useIbis';
import { Button } from '../button/button';
import { icons } from '../../../assets/svg/icons';
import { parseHints } from '../../utils/hints';
import backendTranslation from '../../../main/utils/backendTranslation';
import useForceUpdateOnLangChange from '../../../main/utils/useForceUpdateOnLangChange';

const SidebarButton = ({ control, index, IBIS }) => {
  useForceUpdateOnLangChange();
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <React.Fragment key={index}>
      <Button
        id={`sidebar-button-${index}`}
        newSelector={Link}
        to={control.action.navigate.path}
        icon={icons[control.icon] ?? <b className="h4 mt-2">?</b>}
        disabled={IBIS.isLoading}
        type="menu"
      />
      {control.label && (
        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target={`sidebar-button-${index}`}
          toggle={toggle}
        >
          {backendTranslation(control.label)}
        </Tooltip>
      )}
    </React.Fragment>
  );
};

SidebarButton.propTypes = {
  control: PropTypes.shape({
    icon: PropTypes.string,
    action: PropTypes.object,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
  }),
  index: PropTypes.number,
  IBIS: PropTypes.object,
};

const Sidebar = () => {
  const [ sidebarControls, setSidebarControls ] = useState([]);
  const IBIS = useIBIS(false);

  useEffect(() => {
    if (!IBIS.isLoading)
      setSidebarControls(parseHints(IBIS.node?.hints?.sidebar?.controls));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ IBIS.isLoading ]);

  if (!sidebarControls) return null;
  return (
    <>
      {sidebarControls.map((control, index) => (
        <SidebarButton
          key={index}
          control={control}
          index={index}
          IBIS={IBIS}
        />
      ))}
    </>
  );
};

export default Sidebar;
