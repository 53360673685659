import { createGlobalStyle } from 'styled-components';
import { __env } from '../../envloader';

export const theme = {
  mainLogo: __env.THEME.mainLogo,
  serviceLogo: __env.THEME.serviceLogo,
  colors: {
    primary: {
      900: "#052048",
      800: "#073678",
      700: "#0a408f",
      600: "#0d56bf",
      500: "#106bef",
      400: "#4089f2",
      300: "#70a6f5",
      200: "#9fc4f9",
      100: "#b7d3fa",
      50: "#cfe1fc",
      0: "#e7f0fd",
    },
    red: {
      600: "#BA1B2E",
      500: "#D1293D",
      300: "#F76F7F",
    },
    grey: {
      900: "#171C26",
      800: "#333B4A",
      700: "#464F60",
      600: "#5A6376",
      500: "#687182",
      400: "#868FA0",
      300: "#CBD5E0",
      200: "#BCC2CE",
      100: "#D5DBE5",
      50: "#E9EDF5",
      0: "#F7F9FC",
    },
    white: "#FFFFFF",
    success: "#00A854",
    danger: "#D20724",
    warning: "#FFA216",

    ...__env.THEME.colors,
  },
  fontSize: {
    extraSmall: "10px",
    small: "12px",
    medium: "14px",
    large: "16px",
    ...__env.THEME.fontSize,
  },
  fontWeight: {
    normal: "400",
    medium: "500",
    semiBold: "600",
    bold: "700",
    ...__env.THEME.fontWeight,
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    font-size: ${theme.fontSize.medium};
    line-height: 20px;
    padding: 0 !important;
    overflow-x: hidden;
  }

  button {
    font-size: ${theme.fontSize.medium};
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  a {
    color: ${theme.colors.primary[500]};
  }

  .fade {
    transition: none !important;
  }

  .modal.fade .modal-dialog {
    transition: none !important;
    transform: none !important
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: ${theme.colors.primary[500]};
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: ${theme.colors.primary[500]};
  }

  ${Object.keys(theme.colors.primary).map(
    (key) => `
    .primary-${key} {
      fill: ${theme.colors.primary[key]};
    }
  `
  )}

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.87);
  }

  h1, .h1 {
    font-size: 50px;
    line-height: 50px;
  }

  h2, .h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 32px;
  }

  h4, .h4 {
    font-size: 24px;
    line-height: 16px;
  }

  h5, .h5 {
    font-size: 20px;
    line-height: 28px;
  }

  h6, .h6 {
    font-size: 18px;
    line-height: 28px;
  }

  /*** Notifications ***/

  #root div.redux-toastr {
    .toastr {
      border-radius: 8px;
      background-color: ${theme.colors.grey[0]};;
      color: ${theme.colors.grey[600]};;
      padding: 16px 20px;
      font-size: ${theme.fontSize.medium};
      font-weight: 500;
      letter-spacing: 0.02em;
    
      .rrt-middle-container {
        padding: 0;
        margin-left: 30px;
        width: 95%;

        .rrt-title {
          font-size: 16px;
        }
      }
    
      .rrt-left-container {
        width: 40px;
        .rrt-holder {
          width: 40px;
        }
      }
    }

    .without-text {
      min-height: 50px;

      .rrt-left-container {
        height: auto;
        .rrt-holder {
          margin-top: -32px;
          height: auto;
        }
      }

      .rrt-middle-container {
        .rrt-title {
          margin: 0;
        }
      }
    }
  }

  .content-type-tooltip,
  .bs-tooltip {
    
    .tooltip-inner {
      font-size: ${theme.fontSize.small};
      font-weight: 500;
      text-transform: uppercase;
      padding: 9px 12px;
    }
  }

  
  .rteTextbox{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  // Custom scrollbar
  ::-webkit-scrollbar {
    width: 0.75rem;
  }

  ::-webkit-scrollbar-track {
    background: #D5DBE5;
    width: 0.75rem;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #A1A9B8;
    border-radius: 99px;
  }

  .rpv-full-screen__overlay {
    display: none;
  }

  .hidden-label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;
