import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col } from 'reactstrap';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import MainDisplay from './pages/mainDisplay';
import Sidebar from './pages/sidebar/sidebar';
import { topbarHeight } from './pages/topbar/topbar.styled';
import { sidebarWidth } from './pages/sidebar/sidebar.styled';
import Header from './components/nodes/header/headerContainer';

import FilterPanel from './components/filterPanel/FilterPanel.jsx';
import { useIBIS } from './hooks/useIbis';
import CustomPieChart from './components/charts/CustomPieChart.jsx';

const StyledMainContainer = styled.div`
  display: flex;
  min-height: calc(100vh - ${topbarHeight}px);
`;
const StyledContentContainer = styled(Col)`
  padding: 0;
  max-width: calc(100vw - ${({ sidebar }) => (sidebar ? 0 : sidebarWidth)}px);
  display: flex;
  flex-direction: column;
`;

const Main = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const disableSidebar = queryParams.get("sidebar") === "false";

  const [ isOpenFilterPanel, setIsOpenFilterPanel ] = useState(true);

  const IBIS = useIBIS(false);

  const dontShowSidebar =
    disableSidebar ||
    IBIS.node?.hints?.sidebar?.visibility === "hidden" ||
    IBIS.node?.hints?.sidebar?.visibility === "collapsed";

  const handleOpenFiltePanel = () => {
    setIsOpenFilterPanel(!isOpenFilterPanel);
  };

  useEffect(() => {
    setIsOpenFilterPanel(true);
  }, [ location.pathname ]);

  return (
    <Container fluid className="p-0 m-0">
      <StyledMainContainer className="m-0">
        {!dontShowSidebar && <Sidebar />}
        <FilterPanel isOpen={isOpenFilterPanel}/>
        <StyledContentContainer sidebar={dontShowSidebar.toString()}>
          <Header handleOpenFiltePanel={handleOpenFiltePanel}/>
          <CustomPieChart />
          <MainDisplay toplevel={true} />
        </StyledContentContainer>
      </StyledMainContainer>
    </Container>
  );
};

Main.propTypes = {
  t: PropTypes.func, //HOC
  logIn: PropTypes.func,
  loginStore: PropTypes.object,
  entitiesStore: PropTypes.object,
};

export default Main;
