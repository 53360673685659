import qs from 'qs';
import { __env } from '../../envloader';
import { getGV } from '../../main/utils/globalVariablesProvider';
import axiosInstance from '@axios';
import { isFileEditable } from '../utils/editableFileType';

function getSecret() {
  return getGV("secret");
}

function sendRequest({
  method,
  path,
  data,
  query = {},
  responseType,
  onUploadProgress,
  __silentFor,
  signal,
  headers = {},
}) {
  const fullPath = path[0] === "/" ? path.substr(1) : path;
  const url = `${__env.IBIS_TENANT_URL ?? ""}${fullPath}?${qs.stringify(query)}`;

  const finalHeaders = { ...headers };
  const secret = getSecret();
  if (secret) {
    finalHeaders["X-ibis-Secret"] = secret;
    finalHeaders["Authorization"] = `secret ${secret}`;
  }

  return axiosInstance({
    method,
    url,
    data,
    headers: finalHeaders,
    responseType,
    onUploadProgress,
    __silentFor,
    __useAuthorizationHeader: "onlyForLoggedIn",
    signal,
  });
}

export function fetchDirectory({ path, cursor, fetchAll, pageSize }) {
  let query = cursor ? cursor : pageSize ? { page_size: pageSize } : {};
  query.format = "ui";

  const fetchMore = async (currentResult = []) => {
    const response = await sendRequest({
      method: "get",
      path,
      query,
      __silentFor: [
        { status: 404 },
        { status: 403 },
        { status: 400, code: "INVALID_NODE_TYPE" },
      ],
    });

    // Check if response.data is empty or doesn't have expected properties
    if (!response.data || typeof response.data !== 'object' || Object.keys(response.data).length === 0) {
      return {
        ...response,
        data: {
          items: currentResult,
          more: null,
        },
      };
    }

    const items = [ ...currentResult, ...(response.data.items || []) ];

    if (!fetchAll || !response.data.more) {
      return {
        ...response,
        data: {
          ...response.data,
          items,
          more: response.data.more
            ? qs.parse(response.data.more, { ignoreQueryPrefix: true })
            : null,
        },
      };
    }

    query = qs.parse(response.data.more, { ignoreQueryPrefix: true });
    return fetchMore(items);
  };

  return fetchMore();
}

export async function fetchFile({
  path,
  responseType,
  detectContentType,
  contentType,
}) {
  let finalResponseType = responseType;
  let detectedContentType = contentType;
  let fileMetadata = {};
  if (detectContentType) {
    // If we don't know the content type, fetch it to determine the appropriate request.
    const { headers, data } = await fetchContentType({ path });
    fileMetadata = data;
    detectedContentType = fileMetadata.content_type ?? headers["content-type"];
    const isTextFile = detectedContentType?.startsWith("text/");
    const isImageFile = detectedContentType?.startsWith("image/");
    const isUnsupportedOrLargeFile =
      fileMetadata.size > __env.LARGE_FILE_SIZE ||
      (!isTextFile &&
        !isImageFile &&
        !isFileEditable(detectedContentType)); // We only want to fetch files we can display

    if (isUnsupportedOrLargeFile) {
      return {
        headers,
        data: {
          content: null,
          size: fileMetadata.size,
          contentType: detectedContentType,
          lastModified: fileMetadata.modified,
        },
      };
    }

    if (!finalResponseType) {
      if (detectedContentType?.startsWith("image/")) finalResponseType = "blob";
      if (detectedContentType?.startsWith("text/")) finalResponseType = "text";
      if (detectedContentType === "application/pdf") finalResponseType = "arraybuffer";
    }
  }

  // Fetch the actual file data with the determined response type.
  const fileContent = await sendRequest({
    method: "get",
    path: path,
    contentType: detectedContentType,
    responseType: finalResponseType,
    __silentFor: [ { status: 404 }, { status: 403 } ],
  });
  return {
    ...fileContent, // headers, status, etc.
    data: {
      ...fileMetadata, // file metadata held in ibis
      content:
        finalResponseType === "text" && fileContent.data instanceof Object
          ? JSON.stringify(fileContent.data, null, 2)
          : fileContent.data,
      size: fileMetadata.size,
      contentType: detectedContentType,
      lastModified: fileMetadata.modified,
    },
  };
}


export function getEmbedContent({ path }) {
  return sendRequest({
    method: "get",
    path,
    query: {
      embed: "parent",
      redirect: "explicit",
    },
    __silentFor: [ { status: 404 }, { status: 403 } ],
  });
}

export function touch(path) {
  return sendRequest({
    method: "PUT",
    path,
  });
}

export function fetchContentType({ path }) {
  return sendRequest({
    query: { format: "ui" },
    method: "get",
    path,
    __silentFor: [ { status: 404 }, { status: 403 } ],
  });
}

export function createNode({
  path,
  node,
  onUploadProgress,
  method = "post",
  headers,
  signal,
}) {

  return sendRequest({
    method,
    path,
    data: node,
    onUploadProgress,
    headers,
    signal,
  });
}

export function createDirectory({ path, attributes }) {
  return createNode({ path, node: { attributes } });
}

export function createSymlink({ path, target }) {
  return createNode({
    path,
    headers: {
      "IBIS-TARGET": target,
      "IBIS-TYPE": "symlink",
    },
  });
}

export function createRellink({ path, target, relation }) {
  return createNode({
    path,
    headers: {
      "IBIS-TARGET": target,
      "IBIS-ATTRIBUTES": `relation=${relation}`,
    },
  });
}

export function createFile({ path, file, onUploadProgress, signal }) {
  let formData = new FormData();
  formData.append("content", file);
  
  return createNode({
    path,
    headers: {
      "content-type": "multipart/form-data",
    },
    method: "post",
    ...(file && {
      node: formData,
      onUploadProgress,
      signal
    })
  });
}

export function patchFile({ path, file, onUploadProgress, signal }) {
  let formData = new FormData();
  formData.append("content", file);

  return createNode({
    path,
    method: "put",
    node: formData,
    onUploadProgress,
    headers: { "content-type": "multipart/form-data" },
    signal,
  });
}

export function patchDirectory({ path, attributes }) {
  return createNode({ path, method: "patch", node: { attributes } });
}

export function deleteNode({ path }) {
  return sendRequest({ method: "delete", path });
}

export function getEmbedLink(path) {
  return sendRequest({
    method: "get",
    path,
    query: {
      embed: "parent",
      redirect: "explicit",
    },
    __silentFor: [ { status: 404 }, { status: 403 } ],
  });
}

export function patchIBISfile({ path, content }) {
  const name = path.substring(path.lastIndexOf("/") + 1);

  return sendRequest({
    method: "patch",
    path,
    data: {
      attributes: {},
      content: content,
      content_type: "application/json",
      name: name,
      size: "",
      user_content_last_modified: "",
      type: "file",
    },
  });
}
