import styled from 'styled-components';

import { theme } from './../../../ibis-ui/styles/index';

export const LanguageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LanguageButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${theme.colors.white};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.normal};
  line-height: 20px;
  letter-spacing: 2%;

  path {
    stroke: #FFFFFF;
  }
`;