import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';
import { useLocation } from 'react-router-dom';

import {
  StyledNodesListItem,
  StyledFileName,
  StyledTableCell,
  StyledDate,
  StyledShortDate,
} from './nodesRow.styled';
import { ReactComponent as Clock } from '../../../../../assets/svg/icons/clock.svg';
import { ReactComponent as More } from '../../../../../assets/svg/icons/more.svg';
import { Button } from '../../../button/button';
import { Badge } from '../../../badge/badge';
import Delete from '../../../button/delete';
import Download from '../../../button/download';
import Edit from '../../../button/edit';
import Dropdown from '../../../dropdown/dropdown';
import { useIBIS } from '../../../../hooks/useIbis';
import Loading from '../../../../../main/components/loading';

import ItemIcon from '../../../fileIcon/fileIcon';
import Attributes from '../../attributes/attributes';
import Rename from '../../../button/rename';

const FileCell = ({ cell, node }) => {
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const IBIS = useIBIS(true, `${pathname}${node.name}/${cell.path}`, true);

  if (IBIS.isLoading) return <Loading />;

  const field = IBIS.node?.content?.[cell.field];
  if (!IBIS.node || !field) return <StyledFileName>-</StyledFileName>;

  return <StyledFileName>{field}</StyledFileName>;
};

FileCell.propTypes = {
  cell: PropTypes.object,
  node: PropTypes.object,
};

const CellToComponent = ({ cell, node }) => {
  const [ tooltipID, setTooltipID ] = useState(null);

  const contentType =
    node.content_type && node.type === "file"
      ? node.content_type.split("/")[1].toUpperCase()
      : node.type !== "file"
        ? node.type
        : "-";

  useEffect(() => {
    if (node.type === "file") setTooltipID(uniqueId("content-type-"));
    return () => {
      setTooltipID(null);
    };
  }, [ node.type ]);

  const fileSize = (data) => {
    if (data === 0) {
      return `0 B`;
    }
    const sizes = [ "B", "kB", "MB", "GB", "TB", "PB" ];
    const i = Math.floor(Math.log(data) / Math.log(1000));
    return parseFloat((data / Math.pow(1000, i)).toFixed(0)) + " " + sizes[i];
  };

  if (!cell) return null;

  if (cell.type === "field" && cell.follow) {
    const item = node.items.find((item) => item.name === cell.path);
    if (item && item.followed.json[cell.field])
      return item.followed.json[cell.field];
    return "-";
  }

  if (cell.type === "field") {
    return <FileCell cell={cell} node={node} />;
  }

  const value = node[cell?.property];
  if (cell.property === "content_type")
    return (
      <>
        <Badge id={tooltipID}>{contentType}</Badge>
        {tooltipID && (
          <UncontrolledTooltip
            placementPrefix="content-type-tooltip bs-tooltip"
            placement="bottom"
            target={tooltipID}
          >
            <span>{value}</span>
          </UncontrolledTooltip>
        )}
      </>
    );

  if (cell.property === "size")
    return <Badge>{value !== undefined ? fileSize(value) : "-"}</Badge>;

  if (cell.property === "modified") {
    const dateFormat = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h12",
    };

    const lastModified = value
      ? new Date(value).toLocaleDateString("pl-PL", dateFormat)
      : "-";

    const shortenedDateFormat = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const shortenedLastModified = value
      ? new Date(value).toLocaleDateString("pl-PL", shortenedDateFormat)
      : "-";

    return (
      <div>
        <Clock />
        <StyledShortDate id={`date-${tooltipID}`}>
          {shortenedLastModified}
        </StyledShortDate>
        <StyledDate>{lastModified}</StyledDate>
        {tooltipID && (
          <UncontrolledTooltip
            placementPrefix="date-tooltip bs-tooltip"
            placement="bottom"
            target={`date-${tooltipID}`}
          >
            <span>{lastModified}</span>
          </UncontrolledTooltip>
        )}
      </div>
    );
  }

  if (cell.property === "attributes") {
    return (
      <StyledTableCell>
        <Attributes attributes={value} isHeader={false} />
      </StyledTableCell>
    );
  }

  return <StyledFileName>{value}</StyledFileName>;
};

CellToComponent.propTypes = {
  cell: PropTypes.object,
  node: PropTypes.object,
};

export const NodesRowItem = ({
  data,
  node,
  name,
  type,
  size,
  content_type,
  children,
  onClick,
  onMouseDown,
  innerRef,
  notifyHost,
}) => {
  const nodeRef = useRef();
  const [ dropdown, setDropdown ] = useState(false);

  return (
    <StyledNodesListItem
      onClick={onClick}
      onMouseDown={onMouseDown}
      tabIndex={0}
      role="link"
      ref={innerRef}
    >
      <StyledTableCell>
        {notifyHost ? (
          <input
            type="radio"
            onClick={notifyHost}
            className={"d-flex ml-4"}
            style={{
              cursor: "pointer",
            }}
          ></input>
        ) : (
          <>
            {children}
            <ItemIcon content_type={content_type} type={type} />
          </>
        )}
      </StyledTableCell>
      {data.map((column, index) => (
        <StyledTableCell key={index}>
          <CellToComponent cell={column.cell} node={node} />
        </StyledTableCell>
      ))}
      {notifyHost ? null : (
        <StyledTableCell>
          <div className="pr-4">
            <div ref={nodeRef} className="position-relative">
              <Button
                type="menu"
                icon={<More />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDropdown(!dropdown);
                }}
              />
              {dropdown && (
                <Dropdown
                  triggerRef={nodeRef}
                  changeDropdownState={setDropdown}
                >
                  <Rename currentName={name}/>
                  {type === "file" && <Edit dropdown={true} name={name} />}
                  {type === "file" && <Download name={name} size={size} />}
                  <Delete name={name + (type === "directory" ? "/" : "")} />
                </Dropdown>
              )}
            </div>
          </div>
        </StyledTableCell>
      )}
    </StyledNodesListItem>
  );
};

NodesRowItem.propTypes = {
  data: PropTypes.array,
  node: PropTypes.object,
  content_type: PropTypes.string,
  attributes: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.element,
  onMouseDown: PropTypes.func,
  onClick: PropTypes.func,
  innerRef: PropTypes.func,
  to: PropTypes.string,
  notifyHost: PropTypes.oneOf([ PropTypes.func, null ]),
};
