import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledTab = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 2rem 0.9rem 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${theme.colors.grey[700]};
  border-bottom: 0.4rem solid white;

  ${({ active }) => active ? `
    font-weight: 700;
    border-bottom: 0.4rem solid ${theme.colors.primary[500]};
  ` : ""}
`;


export const StyledTabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.grey[50]};
`;

export const FilterButton = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  background: ${theme.colors.primary["500"]};
  margin-left: 8px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  box-shadow: 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

  span {
    width: 16px;
    height: 2px;
    background-color: rgb(229, 229, 229);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2px;

    ::before {
      content: "";
      width: 2px;
      height: 6px;
      background-color: ${theme.colors.grey["0"]};
      position: absolute;
      border-radius: 1.5px;
      transition: all 0.3s;
    }
  }

    > *:nth-child(1)::before,
    > *:nth-child(3)::before {
      transform: translateX(-4px);
    }

    > *:nth-child(2)::before {
      transform: translateX(4px);
    }

  &:hover {
    background: ${theme.colors.primary["600"]};
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["600"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    
    > *:nth-child(1)::before,
    > *:nth-child(3)::before {
      transform: translateX(4px);
    }
    > *:nth-child(2)::before {
      transform: translateX(-4px);
    }
  }

  &:active {
    background: ${theme.colors.primary["500"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:disabled {
    color: ${theme.colors.primary["0"]};
    background: ${theme.colors.primary["300"]};
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["300"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;