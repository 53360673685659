import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  StyledDropdownIcon,
  StyledDropdownButton,
} from '../dropdown/dropdown.styled';
import { Button } from './button';
import { ReactComponent as EditIcon } from '../../../assets/svg/icons/edit.svg';

const Edit = ({ name = "", isEditing, setIsEditing }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setIsEditing(!isEditing);
  };

  return name.length === 0 ? (
    <Button
      type={isEditing ? "destructive" : "secondary"}
      svgProperty={"fill"}
      icon={<EditIcon />}
      onClick={handleClick}
      className="mr-3"
    >
      {isEditing ? t("ibis-ui:cancel") : t("ibis-ui:edit_content")}
    </Button>
  ) : (
    <StyledDropdownButton className="px-2" onClick={handleClick}>
      <StyledDropdownIcon className="pr-1">
        <EditIcon className="align-self-center" />
      </StyledDropdownIcon>
      {t("ibis-ui:edit_content")}
    </StyledDropdownButton>
  );
};

Edit.propTypes = {
  isEditing: PropTypes.bool,
  name: PropTypes.string,
  setIsEditing: PropTypes.func
};

export default Edit;
