import React, { useState, useEffect } from 'react';

import { useIBIS } from '../../hooks/useIbis';
import { Button } from '../button/button';
import { icons } from '../../../assets/svg/icons';
import { parseHints } from '../../utils/hints';
import backendTranslation from '../../../main/utils/backendTranslation';
import useForceUpdateOnLangChange from '../../../main/utils/useForceUpdateOnLangChange';

const Topbar = () => {
  useForceUpdateOnLangChange();
  const [ topbarControls, setTopbarControls ] = useState([]);
  const IBIS = useIBIS(false);

  useEffect(() => {
    if (!IBIS.isLoading)
      setTopbarControls(parseHints(IBIS.node?.hints?.topbar?.controls));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ IBIS.isLoading ]);

  if (!topbarControls) return null;
  return (
    <>
      {topbarControls.map((control, index) => {
        return (
          <Button
            key={index}
            icon={icons[control.icon]}
            className="mr-2"
            onClick={() => IBIS.controls(control.action)}
            disabled={IBIS.isLoading}
            notFlash
          >
            {backendTranslation(control.label)}
          </Button>
        );
      })}
    </>
  );
};

export default Topbar;
