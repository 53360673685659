import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ReactComponent as Signet } from '../../../assets/svg/icons/signet.svg';
import { StyledSidebar } from './sidebar.styled';
import { theme } from '../../styles';
import { StyledSidebarFlexContainer } from './sidebar.styled';
import SidebarButtons from '../../components/hints/sidebar';

const Sidebar = () => {
  return (
    <StyledSidebar>
      <NavbarBrand tag={Link} to={"/" + window.location.search}>
        {theme.serviceLogo ? (
          <img className="w-100 p-2" src={theme.serviceLogo} alt="logo" />
        ) : (
          // signet paths contain primary-x classes to be colored according to current theme
          <Signet className="h-100" />
        )}
      </NavbarBrand>
      <StyledSidebarFlexContainer>
        <SidebarButtons />
      </StyledSidebarFlexContainer>
    </StyledSidebar>
  );
};

export default Sidebar;
